<div *ngIf="historiaBase" class="mt-5 mb-5">
    <h4>Leia mais</h4>

    <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col" *ngFor="let item of historiasSugeridas">
            <a [href]="'historia/' + item.url" class="text-decoration-none">
                <div class="card h-100" style="max-width: 540px;">
                    <div class="row g-0  h-100">
                        <div class="col-4 align-self-center">
                            <img src="{{item.banner}}" class="img-fluid rounded-start" alt="{{item.titulo}}">
                        </div>
                        <div class="col-8">
                            <div class="card-body">
                                <!-- <h6 class="card-title">{{item.titulo}}</h6> -->
                                <p class="card-text">
                                    <small class="text-body-secondary  text-truncate">{{item.titulo}}</small>
                                </p>
                                <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> -->
                                <!-- <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <!-- <div class="row row-cols-1 row-cols-md-3">
        <div *ngFor="let item of historiasSugeridas">
            <a [href]="'historia/' + item.url" class="text-decoration-none">
                <div class="card mb-3 hand">
                    <div class="row justify-content-start">
                        <div class="col-2" *ngIf="item.banner">
                            <img class="img-fluid rounded-start" src="{{item.banner}}" alt="{{item.titulo}}" />

                        </div>
                        <div class="card-body col-10">
                            <div>{{item.titulo}}</div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>     -->
    <div *ngIf="!historiaBase">
        É necessário informar a história
    </div>
</div>