import { Component } from '@angular/core';

@Component({
  selector: 'app-privacidade',
  templateUrl: './privacidade.component.html',
  styleUrl: './privacidade.component.scss'
})
export class PrivacidadeComponent {

}
