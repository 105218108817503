import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, Subject, map, of, tap } from 'rxjs';
import { Historia } from '../model/historia';
import { HttpClient } from '@angular/common/http';
import {
  CollectionReference,
  Firestore,
  QuerySnapshot,
  addDoc,
  collection,
  collectionSnapshots,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import {
  Storage,
  UploadTaskSnapshot,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { LocalStorageService } from './local_storage.service';
import { isPlatformBrowser } from '@angular/common';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root',
})
export class HistoriasService {
  private http: HttpClient = inject(HttpClient);
  private storage: Storage = inject(Storage);
  private firestore: Firestore = inject(Firestore);
  private localStorageService: LocalStorageService =
    inject(LocalStorageService);

  private historiaCollection: CollectionReference = collection(
    this.firestore,
    'historias'
  );

  private _subject = new Subject<any>();

  data: Historia[] | undefined = undefined;
  isBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get events$() {
    return this._subject.asObservable();
  }

  getHistoriasDisponiveis(): Observable<Historia[]> {
    var o = new Observable<Historia[]>((observer) => {
      try {
        this.getTodasHistorias().subscribe((data: Historia[]) => {
          //console.log(data);
          observer.next(data.filter((x) => x.publicada));
        });
      } catch (error) {
        console.log('Falha');
        console.log(error);
        observer.error(error);
      }
    });

    return o;
  }

  getTodasHistorias(): Observable<Historia[]> {
    if (this.data) {
      return of(this.data);
    }

    return new Observable((observer) => {
      var o = collectionSnapshots(this.historiaCollection) as Observable<any>;

      o.subscribe({
        next: (data) => {
          this.data = data.map((d: any) => {
            var r = d.data();

            r.fid = d.id;
            r.id = d.id;

            return r;
          });

          if (this.isBrowser) {
            this.data = this.processaLidas(this.data as any);
          }

          //console.log(JSON.stringify(this.data));

          observer.next(this.data);
          observer.complete();
        },
      });
    });
  }

  getHistoria(id: string): Observable<Historia | undefined> {
    return new Observable((observer) => {
      if (this.data) {
        var i = this.data.find((x) => x.url == id);
        observer.next(i);
      } else {
        console.log(id + ' => data é nulo');

        this.getHistoriasDisponiveis().subscribe({
          next: (data: Historia[]) => {
            var i = data.find((x) => x.url == id);
            observer.next(i);
          },
        });
        //return observer.next(undefined);
      }
    });
  }

  getMesmaCategoria(historiaBase: Historia): Observable<Historia[]> {
    return this.getHistoriasDisponiveis().pipe(
      map<Historia[], Historia[]>((lista) => {
        var r = lista.filter((x: Historia) => {
          return (
            _.intersection(x.categorias, historiaBase.categorias).length > 0
          );
        });

        var d = _.difference(lista, r);

        if (d.length > 0) {
          r.push(d[_.random(0, d.length)]);
        }

        return r;
      })
    );
  }

  getLeiaMais(historiaBase: Historia): Observable<Historia[]> {
    return this.getMesmaCategoria(historiaBase);
  }

  save(formData: {
    historia: Historia;
    fileUploadElement: any;
  }): Observable<any> {
    return new Observable((observer) => {
      this.uploadBanner(formData.fileUploadElement).subscribe({
        next: (data: any) => {
          if (data) {
            formData.historia.banner = data.full_url;
          }

          formData.historia.lastmod = new Date();

          this.saveFireStore(formData.historia).subscribe({
            next: (data) => {
              console.log(data);
              observer.next(data);
            },
            error: (error) => {
              console.log(error);
              observer.error(error);
            },
            complete: () => {
              observer.complete();
            },
          });
        },
      });
    });
  }

  delete(historia: Historia): Observable<boolean> {
    return new Observable((observer) => {
      var d = doc(this.firestore, `/historias/${historia.fid}`);
      deleteDoc(d)
        .then(() => {
          this.data = undefined;

          observer.next();
        })
        .catch((error) => {
          observer.error(error);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  newEvent(event: any) {
    this._subject.next(event);
  }

  private uploadBanner(
    input: HTMLInputElement
  ): Observable<UploadTaskSnapshot | undefined> {
    return new Observable((observer) => {
      if (!input.files) {
        observer.next(undefined);
        observer.complete();

        return;
      }

      const files: FileList = input.files;

      if (files.length == 0) {
        observer.next(undefined);
        observer.complete();

        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) {
          const storageRef = ref(this.storage, file.name);
          var u = uploadBytesResumable(storageRef, file);

          u.on('state_changed', (data: UploadTaskSnapshot) => {
            if (data.state == 'success') {
              console.log('Upload concluído');
            } else if (data.state == 'running') {
              console.log((data.bytesTransferred / data.totalBytes) * 100);
            }
          });

          u.then((data: UploadTaskSnapshot) => {
            if (data.state == 'success') {
              getDownloadURL(storageRef).then((url: string) => {
                (data as any).full_url = url;

                observer.next(data);
                observer.complete();
              });
            } else {
              console.log(data);
            }
          });
        }
      }
    });
  }

  private saveFireStore(historia: Historia) {
    return new Observable((observer) => {
      var p: any;

      if (historia.fid) {
        p = setDoc(doc(this.firestore, `/historias/${historia.fid}`), historia);
      } else {
        p = addDoc(this.historiaCollection, historia);
      }

      p.then((data: any) => {
        observer.next(data);
        observer.complete();
      }).catch((error: any) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  processaLidas(data: Historia[]) {
    const dataOriginal: Historia[] = [];
    dataOriginal.push(...data);

    try {
      const lidas: [] = this.localStorageService.getObject('lidas');

      if (lidas) {
        data.forEach((item: Historia) => {
          var l = lidas.filter((x: any) => x.url == item.url);

          if (l.length > 0) {
            l.sort((a: any, b: any) => a.data - b.data);
            item.lida = l[0];
          }
        });
      }

      return data;
    } catch (error) {
      console.log(error);
    }

    return dataOriginal;
  }

  atualizaViews(historia: Historia) {
    return new Observable((observer) => {
      var limite = 0;//Math.floor(Math.random() * (32496 - 1857)) + 1857;
      var promise: Promise<void>;
      var docRef = doc(this.firestore, `/historias/${historia.fid}`);
      var data = {
        views: (historia.views || limite) + 1,
      };

      promise = setDoc(docRef, data, { merge: true });

      promise
        .then((data: any) => {
          observer.next();
          observer.complete();
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }
}
