import { NgcCookieConsentConfig } from "ngx-cookieconsent";

export const environment = {
  production: true,
  apiUrl: 'http://my-api-url',
  appVersion: require('../../package.json').version,
  firebase: {
    apiKey: "AIzaSyDXRr5QZ-FMnToQcWOW6lOlCnG7zGogI7U",
    authDomain: "historias-para-ninar-fff76.firebaseapp.com",
    projectId: "historias-para-ninar-fff76",
    storageBucket: "historias-para-ninar-fff76.appspot.com",
    messagingSenderId: "704609176212",
    appId: "1:704609176212:web:8b3dec74e23c9cf2ed4281",
    measurementId: "G-PN4CPR746H"
  },
  cookieConfig: {
    cookie: {
      domain: 'historiasparaninar.com.br' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
      popup: {
        background: '#3C6B86',
      },
      button: {
        background: '#f1d600'
      }
    },
    position: 'bottom-right',
    theme: 'classic',
    type: 'info',
  }
};
