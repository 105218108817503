<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    media="all" />

<div *ngIf="historias && !edicao.editando">
    <button class="btn btn-primary mb-3" (click)="nova()">Nova</button>

    <p-table [value]="historias" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>Título</th>
                <th>Publicada</th>
                <th>Total Palavras</th>
                <th>Categorias</th>
                <th>Banner</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-historia>
            <tr>
                <td>
                    <a routerLink="{{historia.url}}" title="Editar">
                        <i class="fa fa-edit hand"></i>
                    </a>
                </td>
                <td>
                    {{ historia.titulo }}
                    <a href="/historia/{{historia.url}}" target="_blank">                        
                        <i class="fa fa-external-link" style="font-size: 12px;" aria-hidden="true" title="Abrir link"></i>
                    </a>
                </td>
                <td>{{ historia.publicada }}</td>
                <td class="text-start">{{ historia.num_palavras }}</td>
                <td>{{ historia.categorias }}</td>
                <td>
                    <img src="{{historia.banner}}" [alt]="historia.titulo" width="50">
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div *ngIf="edicao.editando && edicao.item">
    <div>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-6 mb-3 mb-md-0">
                <label for="exampleFormControlInput1" class="form-label">Título</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" [(ngModel)]="edicao.item.titulo"
                    (ngModelChange)="getURL(edicao.item.titulo)" maxlength="50">
            </div>
            <div class="col-sm-12 col-md-6 d-flex align-items-end">
                <div class="me-2">
                    <label for="exampleFormControlInput1" class="form-label">Categorias</label>
                    <input type="text" class="form-control mb-1" id="exampleFormControlInput1" [(ngModel)]="categorias"
                        maxlength="100" (keyup.enter)="addCategoria(categorias || '')">
                </div>
                <div>
                    <span *ngFor="let categoria of edicao.item.categorias"
                        class="badge rounded-pill text-bg-primary me-2">
                        {{categoria}} <i class="fa fa-ban ms-1 hand" (click)="deleteCategoria(categoria)"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col">
                <label for="exampleFormControlInput1" class="form-label">Autor</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" [(ngModel)]="edicao.item.autor">
            </div>
            <div class="col">
                <label for="exampleFormControlInput1" class="form-label">URL</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" [(ngModel)]="edicao.item.url"
                    readonly>
            </div>
        </div>

        <div class="mb-3">
            <label for="" class="form-label">Resumo</label>
            <!-- <textarea class="form-control" name="" id="" rows="3" [(ngModel)]="edicao.item.resumo"
                maxlength="200"></textarea> -->
            <angular-editor [(ngModel)]="edicao.item.resumo" (ngModelChange)="getTamanhoTexto()"
                maxlength="200"></angular-editor>
        </div>

        <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Texto</label>
            <angular-editor [(ngModel)]="edicao.item.texto" (ngModelChange)="getTamanhoTexto()"></angular-editor>
        </div>

        <div class="mb-3">
            <label class="form-label" for="fileUpload">Escolha a imagem</label>
            <input #banner class="form-control" id="fileUpload" type="file" #upload>
        </div>

        <div class="mb-3">
            <p>Total de palavras: <span class="fw-bold">{{edicao.item.num_palavras}}</span></p>
            <p>Tempo de leitura: <span class="fw-bold">{{edicao.item.tempo_leitura}} min</span></p>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" [(ngModel)]="edicao.item.publicada">
                <label class="form-check-label" for="flexSwitchCheckChecked">História publicada</label>
            </div>
        </div>
    </div>

    <div class="mb-2 d-flex justify-content-between">
        <div>
            <button class="btn btn-primary me-2" (click)="save()" [disabled]="edicao.enviando">
                {{edicao.enviando ? 'Enviando' : 'Salvar'}}
            </button>
            <button class="btn btn-secondary me-2" [disabled]="edicao.enviando" routerLink="/admin">Cancelar</button>
        </div>
        <button class="btn btn-danger" [disabled]="edicao.enviando" (click)="delete()">Excluir</button>
    </div>

</div>