import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import { LayoutComponent } from './layout/layout.component';
import { AppRoutingModule } from '../app-routing.module';
import { HistoriaComponent } from './historia/historia.component';
import { AdminComponent } from './admin/admin.component';
import { FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { LeiaMaisComponent } from './leia-mais/leia-mais.component';
import { PrivacidadeComponent } from './privacidade/privacidade.component';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

@NgModule({
  declarations: [
    LayoutComponent,
    HomeComponent,
    HistoriaComponent,
    AdminComponent,
    LeiaMaisComponent,
    PrivacidadeComponent,
  ],
  imports: [
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    provideMessaging(() => getMessaging()),
    AppRoutingModule,
    CommonModule,
    CardModule,
    ButtonModule,
    TableModule,
    FormsModule,
    AngularEditorModule,
    ShareButtonModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
})
export class ComponentsModule {}
