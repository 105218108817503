import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Analytics } from '@angular/fire/analytics';
import { ActivatedRoute } from '@angular/router';
import { Historia } from 'src/app/model/historia';
import { HistoriasService } from 'src/app/services/historias.service';
import { LocalStorageService } from 'src/app/services/local_storage.service';
import { MetadataService } from 'src/app/services/metadata.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  private historiasService = inject(HistoriasService);
  private metadataService: MetadataService = inject(MetadataService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private analytics: Analytics = inject(Analytics);

  private lista: Historia[] | undefined = undefined;

  categoria: string | undefined;
  lista_filtrada: Historia[] | undefined = undefined;

  constructor() {
    var p: any = this.activatedRoute.snapshot.params;

    console.log(p);

    if (p.categoria) {
      this.categoria = p.categoria;
    }
  }

  ngOnInit(): void {
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: 'Histórias para Ninar - Mágica na hora de dormir as crianças',
        description:
          'Encante o sono com contos mágicos e clássicos para crianças: o mundo da imaginação aguarda ansiosamente por elas!',
        image:
          'https://www.historiasparaninar.com.br/assets/historias_para_ninar_full.webp',
      });
    }

    this.getHistorias();
  }

  getHistorias() {
    try {
      this.historiasService.getHistoriasDisponiveis().subscribe({
        next: (data) => {
          this.lista = _.chain(data).shuffle().value();
          this.lista_filtrada = [];

          if (this.categoria) {
            this.lista_filtrada.push(
              ...this.lista.filter((x: Historia) => {
                if (x.categorias) {
                  return x.categorias.indexOf(this.categoria!) > -1;
                }

                return false;
              })
            );
          } else {
            this.lista_filtrada.push(...this.lista);
          }
        },
        error: (error) => {
          console.log('Erro ao carregar histórias');
          console.log(error);
        },
      });

      this.historiasService.events$.subscribe((data) => {
        //console.log(data + ' -> ' + data.toLowerCase().normalize('NFD'));

        this.filtraByTexto(data.toLowerCase().normalize('NFD'));
      });
    } catch (error) {
      console.log('Err -> ' + error);
    }
  }

  onImgError(event: any) {
    event.target.src = '/assets/default.webp';
  }

  filtraByTexto(texto: string) {
    if (texto.length == 0) {
      this.lista_filtrada = [];

      if (this.lista_filtrada) {
        this.lista_filtrada.push(...(this.lista || []));
      }

      return;
    }
    if (this.lista) {
      var data = this.lista.filter((item: Historia) => {
        //console.log(texto + ' -> ' + item.titulo.normalize('NFD').toLowerCase());
        var t: string = item.titulo.normalize('NFD').toLowerCase();

        return t.indexOf(texto) > -1;
      });

      this.lista_filtrada = [];
      this.lista_filtrada.push(...data);
    }
  }
}
