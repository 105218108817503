
<div *ngIf="categoria">
    <h4 class="text-center my-5">Histórias de {{categoria}}</h4>
</div>

<div class="row" *ngIf="lista_filtrada">
    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of lista_filtrada; let i=index">
        <div class="card mb-2 hand" routerLink="/historia/{{item.url}}">
            <a routerLink="/historia/{{item.url}}">
                <img class="card-img-top rounded" src="{{item.banner}}" [title]="item.titulo" alt="{{item.titulo}}" title="{{item.titulo}}"
                    (error)="onImgError($event)" [attr.fetchPriority]="i < 2 ? 'high' : 'normal'"
                    [loading]="i < 2 ? 'eager' : 'lazy'" height="200" width="100%"
                    style="background-image: '/assets/default.webp';" />
            </a>
            <div class="card-body">
                <h3 class="card-title h4">{{item.titulo}}</h3>
                <div class="fw-light fst-italic small">Tempo de leitura: {{item.tempo_leitura}} min</div>
                <div class="fw-light fst-italic small" *ngIf="item.lida">Lida em: {{item.lida.data | date:'short'}}
                </div>
                <p class="card-text mt-2" [innerHTML]="item.resumo"></p>
            </div>
        </div>
    </div>
</div>
<div class="h-100 d-flex flex-column justify-content-center align-items-center "
    *ngIf="lista_filtrada && lista_filtrada.length == 0">
    <img src="/assets/empty.webp" width="100" class="mb-3" />
    <span class="fs-6">Não encontramos nenhuma história :(</span>
</div>