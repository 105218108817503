import { Component, Input, OnInit, inject } from '@angular/core';
import { Historia } from 'src/app/model/historia';
import { HistoriasService } from 'src/app/services/historias.service';

@Component({
  selector: 'app-leia-mais',
  templateUrl: './leia-mais.component.html',
  styleUrl: './leia-mais.component.scss',
})
export class LeiaMaisComponent implements OnInit {
  private historiasSevice: HistoriasService = inject(HistoriasService);
  
  @Input() historiaBase: Historia | undefined;

  historiasSugeridas: Historia [] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.historiaBase) {
      this.historiasSevice
        .getLeiaMais(this.historiaBase)
        .subscribe((data: Historia[]) => {
          this.historiasSugeridas = data;
        });
    }
  }
}
