import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Analytics, getAnalytics, logEvent } from '@angular/fire/analytics';
import { getMessaging, getToken } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { onMessage } from '@firebase/messaging';
import { ColorSchemeService } from 'src/app/services/color_scheme.service';
import { HistoriasService } from 'src/app/services/historias.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  private mode_service: ColorSchemeService = inject(ColorSchemeService);
  private historiaService: HistoriasService = inject(HistoriasService);
  private router: Router = inject(Router);

  private analytics: Analytics = inject(Analytics);

  isMenuOpen: boolean = false;
  dark: boolean = this.mode_service.currentActive() == 'dark';
  categorias: string[] = ['Animais', 'Princesas', 'Principes'];
  isServer: boolean;
  appVersion: string = environment.appVersion;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isServer = !isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (!this.isServer) {
      this.setupMessaging();

      this.router.events.subscribe((e) => {
        if (e.type == 12) {
          this.isMenuOpen = false;
        }
      });
    }
  }

  mudaModo(dark: boolean) {
    this.mode_service.update(dark ? 'dark' : 'light');

    logEvent(this.analytics, 'mode_change', {
      from: dark ? 'dark' : 'light',
      to: dark ? 'light' : 'dark',
    });
  }

  busca(event: any) {
    var v = event.value;
    this.historiaService.newEvent(v);

    logEvent(this.analytics, 'search', {
      search_term: event,
    });
  }

  setupMessaging() {
    const messaging = getMessaging();

    getToken(messaging, {
      vapidKey:
        'BIBbzf6iuMq6qy9SHyfumzkdZT2TH9Uz87rqSqDAX2PBI0dLAYzb8Yn5tBu4RvTx8if9p0OhduIU8BtUpEkxor8',
    })
      .then((currentToken) => {
        if (currentToken) {
          //console.log(currentToken);
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          );
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });

    onMessage(messaging, (payload: any) => {
      console.log('Message received. ', payload);
    });
  }
}
