{
  "name": "web",
  "version": "0.0.20",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "prebuild": "",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "publish": "node ../server/index.js && npm --no-git-tag-version version patch && ng build -c production && firebase deploy",
    "dev": "ng serve --open",
    "serve:ssr:web": "node dist/web/server/main.server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.1.2",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/platform-server": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@angular/service-worker": "^17.1.0",
    "@angular/ssr": "^17.1.0",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.1",
    "@fortawesome/free-brands-svg-icons": "^6.5.1",
    "@fortawesome/free-solid-svg-icons": "^6.5.1",
    "@kolkov/angular-editor": "^3.0.0-beta.0",
    "@nguniversal/express-engine": "^16.2.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/underscore": "^1.11.15",
    "bootstrap": "^5.3.2",
    "cookieconsent": "^3.1.1",
    "express": "^4.18.2",
    "isbot": "^4.4.0",
    "ngx-cookieconsent": "^6.0.0",
    "ngx-sharebuttons": "^13.0.0",
    "ngx-translate": "^0.0.1-security",
    "primeng": "^17.3.3",
    "quill": "^1.3.7",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "underscore": "^1.13.6",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.0",
    "@angular/cli": "^17.1.0",
    "@angular/compiler-cli": "^17.1.0",
    "@types/express": "^4.17.21",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.11.5",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.3"
  }
}
