import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Historia } from 'src/app/model/historia';
import { HistoriasService } from 'src/app/services/historias.service';
import { Title } from '@angular/platform-browser';
import { MetadataService } from 'src/app/services/metadata.service';
import { concatAll, map, tap, timeInterval, timer } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local_storage.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { Analytics } from '@angular/fire/analytics';
import { ColorSchemeService } from 'src/app/services/color_scheme.service';

@Component({
  selector: 'app-historia',
  templateUrl: './historia.component.html',
  styleUrl: './historia.component.scss',
})
export class HistoriaComponent implements OnInit {
  private analytics: Analytics = inject(Analytics);

  private currentRoute: ActivatedRoute = inject(ActivatedRoute);
  private route: Router = inject(Router);
  private historiasSevice: HistoriasService = inject(HistoriasService);
  private metadataService: MetadataService = inject(MetadataService);
  private localStorageService: LocalStorageService =
    inject(LocalStorageService);
  private mode_service: ColorSchemeService = inject(ColorSchemeService);

  id: string | undefined;
  texto: Historia | undefined;
  isServer: boolean = false;
  isCarregando: boolean = true;
  currentURL?: string;
  currentColorSchema: string = this.mode_service.currentActive();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    var params: any = this.currentRoute.snapshot.params;
    this.id = params.id;
    this.isServer = !isPlatformBrowser(platformId);

    if (!this.isServer) {
      this.currentURL = window.location.href;
    }

    if (!this.id) {
      this.route.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
    if (this.id) {
      this.historiasSevice.getHistoria(this.id).subscribe({
        next: (data: Historia | undefined) => {
          this.texto = data;

          if (this.texto && this.texto.titulo) {
            const title = `Histórias para ninar - ${this.texto.titulo}`;

            this.metadataService.updateMetadata({
              title: title,
              description: title,
              image: data?.banner,
            });
          }

          this.isCarregando = false;

          if (!this.isServer) {
            this.marcaHistoriaComoLida().subscribe(() => {
              console.log('Marcada como lida!');
            });

            if (data) {
              this.marcaHistoriaComoVista(data).subscribe((v) => {
                console.log(v);
              });
            }
          }
        },
        error: (error) => {
          this.isCarregando = false;
        },
      });
    }
  }

  onImgError(event: any) {
    event.target.src = '/assets/default.webp';
  }

  marcaHistoriaComoVista(historia: Historia) {
    return timer(1000).pipe(
      map((e) => this.historiasSevice.atualizaViews(historia)),
      concatAll()
    );
  }

  marcaHistoriaComoLida() {
    return timer(5000).pipe(
      tap(() => {
        var lidas: any[] = this.localStorageService.getObject('lidas') || [];

        lidas.push({
          data: new Date(),
          url: this.texto?.url,
        });

        this.localStorageService.setObject('lidas', lidas);
      })
    );
  }
}
