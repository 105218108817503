import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { ColorSchemeService } from './services/color_scheme.service';
import {
  NgcCookieConsentService,
  NgcInitializationErrorEvent,
  NgcInitializingEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { getMessaging, getToken } from '@angular/fire/messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private translateService: TranslateService = inject(TranslateService);
  private isServer: boolean = false;

  private cookieService: NgcCookieConsentService = inject(
    NgcCookieConsentService
  );

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(
    private colorSchemeService: ColorSchemeService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // Load Color Scheme
    this.isServer = !isPlatformBrowser(this.platformId);

    if (!this.isServer) {
      this.colorSchemeService.load();      
    }
  }

  ngOnInit() {
    this.setupLang();
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(() => {
      // you can use this.cookieService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.initializingSubscription = this.cookieService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      }
    );

    this.initializedSubscription = this.cookieService.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      }
    );

    this.initializationErrorSubscription =
      this.cookieService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          // the cookieconsent has failed to initialize...
          console.log(
            `initializationError: ${JSON.stringify(event.error?.message)}`
          );
        }
      );

    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  setupLang() {
    this.translateService.addLangs(['en', 'pt']);
    this.translateService.setDefaultLang('pt');

    const browserLang = this.translateService.getBrowserLang();
    var lang = 'en';

    if (browserLang) {
      lang = browserLang.match(/en|pt/) ? browserLang : 'pt';
    }

    this.translateService.use(lang);

    this.translateService //
      .get([
        'cookie.header',
        'cookie.message',
        'cookie.dismiss',
        'cookie.allow',
        'cookie.deny',
        'cookie.link',
        'cookie.policy',
      ])
      .subscribe((data) => {
        if (this.cookieService.getConfig()) {
          var content = this.cookieService.getConfig().content || {};

          if (content) {
            // Override default messages with the translated ones
            content.header = data['cookie.header'];
            content.message = data['cookie.message'];
            content.dismiss = data['cookie.dismiss'];
            content.allow = data['cookie.allow'];
            content.deny = data['cookie.deny'];
            content.link = data['cookie.link'];
            content.policy = data['cookie.policy'];

            this.cookieService.getConfig().content = content;

            this.cookieService.destroy(); // remove previous cookie bar (with default messages)
            this.cookieService.init(this.cookieService.getConfig()); // update config with translated messages
          }
        }
      });
  }
}
