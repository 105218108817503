<div *ngIf="texto">
    <div class="col-12 px-0">
        <img src="{{texto.banner}}" class="img-fluid rounded" (error)="onImgError($event)">
        <h4 class="display-6 text-center mt-4 mb-1 fw-bold">{{texto.titulo}}</h4>
        <div class="row my-3 text-center">
            <div class="col-12 col-md-4 fw-light fst-italic">Número de Palavras: {{texto.num_palavras}}</div>
            <div class="col-12 col-md-4 fw-light fst-italic">Tempo de leitura: {{texto.tempo_leitura}} min</div>
            <!-- <div class="col-12 col-md-4 fw-light fst-italic" *ngIf="texto.lida">Lida em: {{texto.lida.data | date:'short'}}</div> -->
            <div class="col-12 col-md-4 fw-light fst-italic">
                <i class="fa fa-eye" aria-hidden="true">{{texto.views | number}} visualizações</i>
            </div>
        </div>
        <div class="d-flex justify-content-center pb-4">
            <share-buttons [theme]="'circles-dark'"
                [include]="['whatsapp', 'facebook','twitter','pinterest','telegram','messenger','print','copy']"
                [show]="5" [autoSetMeta]="true"></share-buttons>
        </div>
        <div class="fs-4 text-justify">
            <span [innerHTML]="texto.texto"></span>
        </div>
        <app-leia-mais [historiaBase]="texto"></app-leia-mais>
        <div *ngIf="currentURL" class="fb-comments" [attr.data-colorscheme]="currentColorSchema"
            [attr.data-href]="currentURL" data-width="100%" data-numposts="5"></div>
    </div>
</div>
<div *ngIf="!texto" class="vh-100">

    <div class="d-flex flex-column justify-content-center align-items-center align-middle vh-100" *ngIf="isCarregando">
        <div class="pacm"></div>
        <span class="mt-3">Carregando...</span>
    </div>

    <div *ngIf="!isCarregando">
        Texto não encontrado
    </div>
</div>