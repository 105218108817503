import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { HistoriaComponent } from './components/historia/historia.component';
import { AdminComponent } from './components/admin/admin.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { PrivacidadeComponent } from './components/privacidade/privacidade.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },      
      { path: 'admin', component: AdminComponent },
      { path: '404', component: NotFoundComponent },
      { path: 'privacidade', component: PrivacidadeComponent },
      { path: 'historia/:id', component: HistoriaComponent },      
      { path: 'admin/:id', component: AdminComponent },
      { path: ':categoria', component: HomeComponent },      
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
