<header>
    <h2 class="text-center my-4">Política de Privacidade</h2>
</header>
<section>
    <p>Última atualização: 22/01/2024</p>

    <p>O site "Histórias Para Ninar" valoriza e respeita a privacidade dos seus usuários. Esta política de privacidade descreve como as informações pessoais são coletadas, usadas e compartilhadas ao utilizar nosso site.</p>

    <h4>Informações Coletadas</h4>
    <p>Podemos coletar informações pessoais, como nome, endereço de e-mail e dados demográficos, quando você se cadastra em nosso site, assina nossa newsletter ou interage de outras formas conosco.</p>

    <h4>Uso das Informações</h4>
    <p>As informações coletadas podem ser utilizadas para personalizar sua experiência no site, enviar newsletters, responder a perguntas e melhorar nossos serviços.</p>

    <h4>Compartilhamento de Informações</h4>
    <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para cumprir obrigações legais ou proteger nossos direitos.</p>

    <h4>Cookies</h4>
    <p>Utilizamos cookies para melhorar a experiência do usuário. Ao navegar em nosso site, você concorda com o uso de cookies.</p>

    <h4>Segurança</h4>
    <p>Adotamos medidas de segurança para proteger suas informações contra acesso não autorizado ou divulgação.</p>

    <h4>Alterações nesta Política</h4>
    <p>Reservamo-nos o direito de atualizar esta política de privacidade periodicamente. Recomendamos que revise esta página regularmente para estar ciente de quaisquer alterações.</p>

    <h4>Contato</h4>
    <p>Se tiver dúvidas ou preocupações sobre nossa política de privacidade, entre em contato conosco.</p>
</section>

<footer>
    <p>Copyright © 2024 Histórias Para Ninar. Todos os direitos reservados.</p>
</footer>