<div class="vh-100">
    <nav class="navbar navbar-expand-lg bg-body-tertiary" style="background-color: #3C6B86 !important">
        <div class="container-fluid">
            <h1 class="title">
                <a href="https://www.historiasparaninar.com.br/" rel="home">Histórias para ninar</a>
            </h1>
            <a class="navbar-brand" href="/">
                <img src="/assets/logo_ss.webp" height="40px" alt="Histórias para Ninar" />
            </a>
            <button class="navbar-toggler custom-toggler" type="button" aria-label="Toggle navigation"
                (click)="isMenuOpen = !isMenuOpen">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse" id="navbarSupportedContent" [ngClass]="{'collapse': !isMenuOpen}">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link active text-white" aria-current="page" href="/">Todas</a>
                    </li>
                    <li class="nav-item" *ngFor="let item of categorias">
                        <a class="nav-link active text-white" aria-current="page" href="/{{item.toLowerCase()}}"
                            [innerText]="item"></a>
                    </li>
                    <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Dropdown
                    </a>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                </li> -->
                    <!-- <li class="nav-item">
                    <a class="nav-link disabled" aria-disabled="true">Disabled</a>
                </li> -->
                </ul>
                <div class="form-check form-switch me-2">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        [(ngModel)]="dark" (change)="mudaModo(dark)">
                    <label class="form-check-label text-white" for="flexSwitchCheckDefault">Modo</label>
                </div>
                <form class="d-flex" role="search">
                    <input class="form-control me-2" type="search" placeholder="Buscar" aria-label="Buscar"
                        (keyup)="busca($event.srcElement)">
                </form>
            </div>
        </div>
    </nav>

    <main class="container">
        <router-outlet></router-outlet>
    </main>

    <footer class="text-center pb-3 text-direitos mt-5">
        <div class="">Histórias para Ninar - 2024</div>
        <div>Todos os direitos reservados</div>
        <div>Current version: {{appVersion}}</div>
    </footer>
</div>