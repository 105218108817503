import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Analytics } from '@angular/fire/analytics';

import { ActivatedRoute, Router } from '@angular/router';
import { Historia } from 'src/app/model/historia';
import { HistoriasService } from 'src/app/services/historias.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
})
export class AdminComponent implements OnInit {
  private analytics: Analytics = inject(Analytics);

  private historiaService: HistoriasService = inject(HistoriasService);
  private activeRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);

  @ViewChild('banner') bannerUploadElement: ElementRef | undefined;

  historias: Historia[] | undefined;
  categorias: string | undefined = '';
  htmlContent: string = '';
  edicao: {
    editando: boolean;
    id: string;
    item: Historia | undefined;
    enviando: boolean;
  } = {
    editando: false,
    id: '',
    item: undefined,
    enviando: false,
  };

  constructor() {
    var id = (this.activeRoute.snapshot.params as any).id;

    if (id) {
      this.historiaService.getHistoria(id).subscribe({
        next: (data: Historia | undefined) => {
          if (data) {
            this.edicao = {
              id: id,
              editando: true,
              item: data,
              enviando: false,
            };

            //this.categorias = this.edicao.item?.categorias.join(',');
          }
        },
      });
    } else if (this.edicao.editando) {
    } else {
      this.getHistorias();
    }
  }

  ngOnInit(): void {}

  getHistorias() {
    this.historiaService.getTodasHistorias().subscribe({
      next: (data: Historia[]) => {
        this.historias = data;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getTamanhoTexto() {
    if (this.edicao.item && this.edicao.item.texto) {
      this.edicao.item.num_palavras = this.edicao.item.texto.split(' ').length;
      this.edicao.item.tempo_leitura = Math.round(
        this.edicao.item.num_palavras / 125
      );
    }
  }

  save() {
    if (this.edicao.item) {
      this.edicao.enviando = true;

      const d = {
        historia: this.edicao.item,
        fileUploadElement: this.bannerUploadElement?.nativeElement,
      };

      if (this.edicao.item) {
        this.edicao.item.data_criacao = new Date();

        this.historiaService.save(d).subscribe({
          next: (data) => {},
          error: (error) => {
            this.edicao.enviando = false;
            console.error(error);
          },
          complete: () => {
            this.edicao.enviando = false;

            this.router.navigateByUrl('/admin');
          },
        });
      }
    }
  }

  nova() {
    this.edicao.editando = true;

    this.edicao.item = {
      num_palavras: 0,
      resumo: '',
      titulo: '',
      texto: '',
      banner: '',
      id: '',
      tempo_leitura: 1,
      url: '',
      autor: '',
      lastmod: new Date(),
      categorias: [],
      publicada: false,
      data_criacao: new Date(),
      views: 0
    };
  }

  delete() {
    if (this.edicao.item) {
      this.historiaService.delete(this.edicao.item).subscribe({
        next: (data) => {
          this.router.navigateByUrl('/admin');
        },
      });
    }
  }

  deleteCategoria(categoria: string) {
    if (this.edicao.item) {
      var i = this.edicao.item.categorias.findIndex((x) => x == categoria);

      if (i > -1) {
        this.edicao.item.categorias.splice(i, 1);
      }
    }
  }

  addCategoria(categoria: string) {
    if (this.edicao.item) {
      if (!this.edicao.item.categorias) {
        this.edicao.item.categorias = [];
      }

      this.edicao.item.categorias.push(categoria);
    }
  }

  getURL(titulo: string) {
    if (this.edicao.item) {
      //var url = titulo.toLowerCase().trim().replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9-_]/g, '');
      var url = titulo
        .toLowerCase()
        .trim()
        .replaceAll(' ', '-')
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9-_]/g, '');
      this.edicao.item.url = url;
    }
  }
}
