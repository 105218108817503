import {
  APP_INITIALIZER,
  LOCALE_ID,
  NgModule,
  inject,
  isDevMode,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MetadataService } from './services/metadata.service';
import { HOST_URL } from './tokens/host-url';

import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { ShareButtonModule } from 'ngx-sharebuttons/button';


registerLocaleData(localePT);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    NgcCookieConsentModule.forRoot(
      environment.cookieConfig as NgcCookieConsentConfig
    ),
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ComponentsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    provideClientHydration(),
    MetadataService,
    { provide: HOST_URL, useValue: 'https://wwww.historiasparaninar.com.br' },
    { provide: LOCALE_ID, useValue: 'pt-br' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
